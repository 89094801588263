import ColorFilterItem from "./ColorFilterItem"

const ColorFilterSelector = ({filterList, selectedList, addFilter, filterType, removeFilter}) => {
    const addHandler = (item) => {
        addFilter(filterType, item)
    }

    const removeHandler = (item) => {
        removeFilter(filterType, item)
    }

    return (
        <div
            style={{
                display: 'flex',
                columnGap: '24px',
                rowGap: '24px',
                marginTop: '12px',
                flexWrap: 'wrap'
            }}
        >
            {
                [...filterList].map((item, index) => {
                    return (
                        <ColorFilterItem 
                            key={index}
                            filterText={item}
                            selected={[...selectedList].includes(item)}
                            selectedList={selectedList}
                            addHandler={addHandler}
                            removeHandler={removeHandler}
                            
                        />
                    )
                })
            }
        </div>
    )
}


export default ColorFilterSelector