import { BiFilter, BiSolidCircle } from 'react-icons/bi';
import iconChevronDown from '../../assets/icons/icon-chevron-down.svg'

const FilterBox = ({filterText, buttonHandler, selected}) => {
    return (
        <div
            onClick={()=>{buttonHandler()}}
            style={{
                background: '#1A1A1A',
                flexGrow: '0',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '20px',
                borderRadius: '10px',
                zIndex: '0',
                border: selected ? '1px solid #999999' : '1px solid #1A1A1A',
                cursor: 'pointer',
            }}
        >
            <span
                style={{
                    fontFamily: "PretendardRegular",
                    color: '#FFF',
                    opacity: '0.8',
                    fontSize: '18px',
                    lineHeight: '1.3',
                    letterSpacing: '0.2px',
                    textAlign: 'left',
                    flexGrow: '0',
                }}
            >
                {filterText}
            </span>



            {
                selected ?
                    <div
                        style={{
                            width: '16px',
                            height: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            opacity: '0.8',
                            marginLeft: '18px',
                            color: '#FFF',
                            // opacity: '0.8',
                            
                        }}
                    >
                        <BiSolidCircle/>
                    </div>
                :
                    <div
                        style={{
                            width: '16px',
                            height: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            opacity: '0.8',
                            marginLeft: '18px',
                            color: '#FFF',
                            
                        }}
                    >
                        <BiFilter/>
                    </div>
            }
            


        </div>
    )
}

export default FilterBox;