import React from 'react';

const DotsSpinner = ({ size = 100, dotSize = 15, dotCount = 5, speed = 0.5, spread = 720, color = '#3498db' }) => {
  const dots = Array(dotCount).fill(0); // 점의 개수만큼 배열을 생성

  const styles = `
    .dots {
      width: ${size}px;
      height: ${size}px;
      position: relative;
    }

    .dot {
        width: ${size}px;
        height: ${size}px;
        animation: dwl-dot-spin ${speed * 5}s infinite linear both;
        position: absolute;
    }

    .dot::before {
        content: "";
        display: block;
        width: ${dotSize}px;
        height: ${dotSize}px;
        background-color: ${color};
        border-radius: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        bottom: 0;
        left: 50%;
    }

    @keyframes dwl-dot-spin {
        0% {
            transform: rotate(0deg);
            animation-timing-function: cubic-bezier(0.390, 0.575, 0.565, 1.000);
            opacity: 1;
        }
        
        2% {
            transform: rotate(20deg);
            animation-timing-function: linear;
            opacity: 1;
        }
        
        30% {
            transform: rotate(180deg);
            animation-timing-function: cubic-bezier(0.445, 0.050, 0.550, 0.950);
            opacity: 1;
        }
        
        41% {
            transform: rotate(380deg);
            animation-timing-function: linear;
            opacity: 1;
        }
        
        69% {
            transform: rotate(520deg);
            animation-timing-function: cubic-bezier(0.445, 0.050, 0.550, 0.950);
            opacity: 1;
        }
        
        76% {
            opacity: 1;
        }
        
        76.1% {
            opacity: 0;
        }
        
        80% {
            transform: rotate(720deg);
        }
        
        100% {
            opacity: 0;
        }
    }
  `;

  return (
    <div>
      {/* 컴포넌트 내에 스타일 추가 */}
      <style>{styles}</style>
      
      <div className="dots">
        {dots.map((_, i) => (
          <div
            key={i}
            className="dot"
            style={{
              animationDelay: `${(i * speed) / (dotCount + 2) * -1}s`,
              transform: `rotate(${(i * spread) / (dotCount - 1)}deg)`
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default DotsSpinner
