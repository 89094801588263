import { useState } from "react";

const KeywordItem = ({eventHandler, text}) => {
    const [isHovered, setIsHovered] = useState(false); // 마우스 오버 상태 관리

    const handleMouseOver = () => {
        setIsHovered(true);  // 마우스가 올라가면 상태를 true로 변경
    };

    const handleMouseOut = () => {
        setIsHovered(false);  // 마우스가 떠나면 상태를 false로 변경
    };

    

    return (
        <>
            <span
                onClick={()=>{
                    eventHandler()
                }}
                onMouseOver={handleMouseOver}  // 마우스 오버 이벤트
                onMouseOut={handleMouseOut}    // 마우스 아웃 이벤트
                style={{
                    color: '#ffffff',  // 마우스 오버 시 색상을 하얀색으로 변경
                    fontSize: '16px',
                    lineHeight: '1.3',
                    flexGrow: '0.5',
                    letterSpacing: 'normal',
                    textAlign: 'left',
                    opacity: isHovered ? '1.0':'0.5',
                    textShadow: '0 4px 4px rgba(0,0,0,0.25)',
                    cursor: 'pointer',
                    transition: 'color 0.2s ease'  // 색상 변경을 부드럽게
                }}
            >
                
                {text}
            </span>
        </>
    )
}

export default KeywordItem