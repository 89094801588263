import { useState } from "react";
import { useNavigate } from "react-router-dom";

const VideoItem = ({ item }) => {
    const navigate = useNavigate()


    const [src, setSrc] = useState(item?.thumbnail_path); // 초기 이미지를 static 이미지로 설정
    const [mouseOver, setMouseOver] = useState(false)


    const handleMouseOver = () => {
        setMouseOver(true)

        setSrc(item?.preview_path); // 마우스 오버 시 animated 이미지로 변경
    };

    const handleMouseOut = () => {
        setMouseOver(false)

        setSrc(item?.thumbnail_path); // 마우스 아웃 시 static 이미지로 변경
    };
  
    
    return (
        <>


            <div
                onClick={() => {
                    navigate(`/video/${item?.id}`);
                }}
                style={{
                    display: 'flex',                // flexbox 사용
                    justifyContent: 'center',       // 수평 중앙 정렬
                    alignItems: 'center',           // 수직 중앙 정렬
                    width: '100%',                  // 너비 100%
                    aspectRatio: '44/26',
                    position: 'relative',           // 절대 배치 기준
                    cursor: 'pointer',
                    overflow: 'hidden',             // 넘치는 부분 숨김
                    border: '1px solid #FFFFFF10'
                }}
            >


                <div
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    style={{
                        position: 'absolute',
                        left: '0',
                        top: '0',
                        width: '100%',
                        height: '100%',
                        background: mouseOver? '#00000050' : 'transparent',
                        zIndex: '10',
                        display: 'flex',
                        alignItems: 'end'
                    }}
                >
                    {
                        mouseOver ?
                            <span
                                style={{
                                    color: 'white',
                                    margin: '12px 12px',
                                    fontFamily: "PretendardSemiBold",
                                    zIndex: '1',
                                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'  // 그림자 옵션
                                }}
                            
                            >
                                {item?.title}

                            </span>
                        : 

                            null
                    }

                </div> 

                <img
                    src={src}
 
                    style={{
                        minWidth: '120%',           // 이미지가 컨테이너의 너비에 맞춰 확장되도록 설정
                        minHeight: '120%',          // 이미지가 컨테이너의 높이에 맞춰 확장되도록 설정
                        objectFit: 'contain',         // 비율을 유지하며 잘리더라도 꽉 채움
                    }}
                />

 




            </div>
        </>
    );
}

export default VideoItem;