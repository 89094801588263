const CustomButton = ({buttonText, eventHandler}) => {
    return (
        <div
            onClick={() => { eventHandler() }}
            style={{
                padding: '16px 20px',
                border: '1px solid #ffffff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '10px'
            }}
        >
            <span style={{
                color: 'white',
                fontFamily: "PretendardRegular",
                fontSize: '18px',
                verticalAlign: 'center',
            }}>
                {buttonText}
            </span>


        </div>
    )
}

export default CustomButton