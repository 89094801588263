// const FILTER_INFO_SAMPLE = {
//     'region': {
//         '서울': 1,
//         '경기남부': 2,
//         '경기북부': 3,
//         '인천': 4,
//         '제주': 5,
//         '부산': 6,
//         '대구': 7,
//         '광주': 8,
//         '울산': 9,
//         '대전': 10,
//         '세종': 11,
//         '동해': 12,
//         '서해': 13,
//         '전라도': 14,
//         '경상도': 15,
//         '강원도': 16,
//         '충청도': 17,
//         '학교 및 대학교': 18,
//         '미특정': 19,
//         '해외': 20,
//         'VFX': 21,
//         '일반': 22
//     },
//     'resolution': {
//         'FHD': 1,
//         '3K': 2,
//         '4K': 3,
//         '5K+': 4
//     },
//     'shot_type': {
//         '접사': 1,
//         '클로즈업': 2,
//         '미디엄샷': 3,
//         '풀샷': 4,
//         '익스트림 와이드': 5,
//         '픽스(삼각대)': 6,
//         '부드러운 움직임': 7,
//         '핸드헬드': 8,
//         'FPV': 9
//     },
//     'shot_info': {
//         '일반': 1,
//         '드론': 2,
//         '타임랩스': 3,
//         '수중촬영': 4,
//         'VFX': 5,
//         '낮': 6,
//         '밤': 7,
//         '일출&일몰': 8,
//         '시간 미특정': 9,
//         '실내': 10,
//         '실외': 11,
//         '호리존': 12,
//         '장소 미특정': 13
//     },
//     'character': {
//         '인물없음': 1,
//         '인물포함': 2,
//         '1명': 3,
//         '2명': 4,
//         '3명+': 5,
//         '아기': 6,
//         '어린이': 7,
//         '청소년': 8,
//         '성인': 9,
//         '중장년': 10,
//         '남성': 11,
//         '여성': 12,
//         '커플': 13
//     },
//     'speed': {
//         '저배속 | Slow': 1,
//         '보통 | Realtime': 2,
//         '고배속 | Fast': 3
//     },
//     'theme': {
//         '여행': 1,
//         '음악': 2,
//         '스포츠': 3,
//         '요리': 4,
//         '교육': 5,
//         '뷰티': 6,
//         '라이프스타일': 7,
//         '자연': 8,
//         '과학': 9,
//         '미술': 10,
//         '애니메이션': 11,
//         '밝음': 12,
//         '어두움': 13,
//         '차가움': 14,
//         '고전적': 15,
//         '현대적': 16,
//         '신비로움': 17,
//         '감성적': 18,
//         '따뜻함': 19,
//         '귀여움': 20,
//         '영화적': 21
//     },
//     'color_scheme': {
//         '흑백': 1,
//         '컬러': 2,
//         '검정색': 3,
//         '회색': 4,
//         '흰색': 5,
//         '빨강색': 6,
//         '주황색': 7,
//         '노랑색': 8,
//         '연두색': 9,
//         '초록색': 10,
//         '하늘색': 11,
//         '파랑색': 12,
//         '보라색': 13,
//         '분홍색': 14
//     }
// }


export const FILTER_CATEGORY_MAPPING = {
    '서울': '지역',
    '경기남부': '지역',
    '경기북부': '지역',
    '인천': '지역',
    '제주': '지역',
    '부산': '지역',
    '대구': '지역',
    '광주': '지역',
    '울산': '지역',
    '대전': '지역',
    '세종': '지역',
    '동해': '지역',
    '서해': '지역',
    '전라도': '지역',
    '경상도': '지역',
    '강원도': '지역',
    '충청도': '지역',
    '학교 및 대학교': '지역',
    '미특정': '지역',
    '해외': '지역',
    'VFX': '지역',
    '일반': '지역',

    'FHD': '최대해상도',
    '3K': '최대해상도',
    '4K': '최대해상도',
    '5K+': '최대해상도',

    '접사': '촬영타입',
    '클로즈업': '촬영타입',
    '미디엄샷': '촬영타입',
    '풀샷': '촬영타입',
    '익스트림 와이드': '촬영타입',
    '픽스(삼각대)': '촬영타입',
    '부드러운 움직임': '촬영타입',
    '핸드헬드': '촬영타입',
    'FPV': '촬영타입',

    '일반': '촬영정보',
    '드론': '촬영정보',
    '타임랩스': '촬영정보',
    '수중촬영': '촬영정보',
    'VFX': '촬영정보',
    '낮': '촬영정보',
    '밤': '촬영정보',
    '일출&일몰': '촬영정보',
    '시간 미특정': '촬영정보',
    '실내': '촬영정보',
    '실외': '촬영정보',
    '호리존': '촬영정보',
    '장소 미특정': '촬영정보',

    '인물없음': '인물',
    '인물포함': '인물',
    '1명': '인물',
    '2명': '인물',
    '3명+': '인물',
    '아기': '인물',
    '어린이': '인물',
    '청소년': '인물',
    '성인': '인물',
    '중장년': '인물',
    '남성': '인물',
    '여성': '인물',
    '커플': '인물',

    '저배속 | Slow': '속도',
    '보통 | Realtime': '속도',
    '고배속 | Fast': '속도',

    '여행': '테마',
    '음악': '테마',
    '스포츠': '테마',
    '요리': '테마',
    '교육': '테마',
    '뷰티': '테마',
    '라이프스타일': '테마',
    '자연': '테마',
    '과학': '테마',
    '미술': '테마',
    '애니메이션': '테마',
    '밝음': '테마',
    '어두움': '테마',
    '차가움': '테마',
    '고전적': '테마',
    '현대적': '테마',
    '신비로움': '테마',
    '감성적': '테마',
    '따뜻함': '테마',
    '귀여움': '테마',
    '영화적': '테마',

    '흑백': '색',
    '컬러': '색',
    '검정색': '색',
    '회색': '색',
    '흰색': '색',
    '빨강색': '색',
    '주황색': '색',
    '노랑색': '색',
    '연두색': '색',
    '초록색': '색',
    '하늘색': '색',
    '파랑색': '색',
    '보라색': '색',
    '분홍색': '색'
}



export const FILTER_INFO = {
    '지역': {
        '서울': 1,
        '경기남부': 2,
        '경기북부': 3,
        '인천': 4,
        '제주': 5,
        '부산': 6,
        '대구': 7,
        '광주': 8,
        '울산': 9,
        '대전': 10,
        '세종': 11,
        '동해': 12,
        '서해': 13,
        '전라도': 14,
        '경상도': 15,
        '강원도': 16,
        '충청도': 17,
        '학교 및 대학교': 18,
        '미특정': 19,
        '해외': 20,
        'VFX': 21,
        '일반': 22
    },
    '최대해상도': {
        'FHD': 1,
        '3K': 2,
        '4K': 3,
        '5K+': 4
    },
    '촬영타입': {
        '접사': 1,
        '클로즈업': 2,
        '미디엄샷': 3,
        '풀샷': 4,
        '익스트림 와이드': 5,
        '픽스(삼각대)': 6,
        '부드러운 움직임': 7,
        '핸드헬드': 8,
        'FPV': 9
    },
    '촬영정보': {
        '일반': 1,
        '드론': 2,
        '타임랩스': 3,
        '수중촬영': 4,
        'VFX': 5,
        '낮': 6,
        '밤': 7,
        '일출&일몰': 8,
        '시간 미특정': 9,
        '실내': 10,
        '실외': 11,
        '호리존': 12,
        '장소 미특정': 13
    },
    '인물': {
        '인물없음': 1,
        '인물포함': 2,
        '1명': 3,
        '2명': 4,
        '3명+': 5,
        '아기': 6,
        '어린이': 7,
        '청소년': 8,
        '성인': 9,
        '중장년': 10,
        '남성': 11,
        '여성': 12,
        '커플': 13
    },
    '속도': {
        '저배속 | Slow': 1,
        '보통 | Realtime': 2,
        '고배속 | Fast': 3
    },
    '테마': {
        '여행': 1,
        '음악': 2,
        '스포츠': 3,
        '요리': 4,
        '교육': 5,
        '뷰티': 6,
        '라이프스타일': 7,
        '자연': 8,
        '과학': 9,
        '미술': 10,
        '애니메이션': 11,
        '밝음': 12,
        '어두움': 13,
        '차가움': 14,
        '고전적': 15,
        '현대적': 16,
        '신비로움': 17,
        '감성적': 18,
        '따뜻함': 19,
        '귀여움': 20,
        '영화적': 21
    },
    '색': {
        '흑백': 1,
        '컬러': 2,
        '검정색': 3,
        '회색': 4,
        '흰색': 5,
        '빨강색': 6,
        '주황색': 7,
        '노랑색': 8,
        '연두색': 9,
        '초록색': 10,
        '하늘색': 11,
        '파랑색': 12,
        '보라색': 13,
        '분홍색': 14
    }
}

