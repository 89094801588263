import iconChevronDown from '../../assets/icons/icon-chevron-down.svg'
import { COLOR_MAPPING } from '../../constants/colors'

const ColorFilterItem = ({filterText, selected, addHandler, removeHandler, selectedList}) => {
    return (
        <div
            onClick={()=>{
                if(selectedList.includes(filterText)) {
                    removeHandler(filterText)
                } else {
                    addHandler(filterText)
                }
            }}
            style={{
                background: COLOR_MAPPING[filterText],
                flexGrow: '0',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '32px',
                height: '32px',
                borderRadius: '32px',
                border: selected ? '3px solid #FFFFFFEE' : '3px solid #1A1A1A',
                cursor: 'pointer'
            }}
        >
            {/* <span
                style={{
                    fontFamily: "PretendardRegular",
                    color: '#FFF',
                    opacity: '0.8',
                    fontSize: '15px',
                    lineHeight: '1.3',
                    letterSpacing: '0.2px',
                    textAlign: 'left',
                    flexGrow: '0',
                }}
            >
                {filterText}
            </span> */}

        </div>
    )
}

export default ColorFilterItem;