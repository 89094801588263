import mainLogo from '../../assets/images/logo.svg'
import instgramIcon from '../../assets/images/icon-instagram.svg'

const Footer = () => {
    const SERVICE_TERM_URL = "https://anglero.notion.site/10617cecd77980ebad3bf01d9897604a?pvs=4"
    const PRIVACY_TERM_URL = "http://anglero.notion.site"
    const INSTAGRAM_LINK_URL = "https://www.instagram.com/anglero.kr"

    return (
        <div
            style={{
                padding: '50px 360px',
                display: 'flex',
                justifyContent: 'center',
                background: 'black',
            }}
        >
            <div
                style={{
                    width: '1200px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        width: '1200px',
                        height: '100.5px',
                        display: 'flex',
                    }}
                >
                    <div
                        style={{
                            flex: '1',
                        }}
                    >
                        <div
                            style={{
                                width: '376px',
                                height: '100px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                padding: '0'
                            }}
                        >
                            <img src={mainLogo} style={{
                                width: '144px',
                                cursor: 'pointer'
                            }}/>

                            <div
                                style={{
                                    display: 'flex',
                                    columnGap: '24px',
                                }}
                            >
                                <span
                                    onClick={() => {
                                        window.location.href = SERVICE_TERM_URL
                                    }}
                                    style={{
                                        height: '20px',
                                        alignSelf: 'stretch',
                                        flexGrow: '0',
                                        fontFamily: "PretendardRegular",
                                        lineHeight: '1.4',
                                        letterSpacing: '-0.28px',
                                        textAlign: 'left',
                                        color: '#D9D9D9',
                                        cursor: 'pointer'
                                    }}
                                >
                                    이용약관
                                </span>

                                <span
                                    onClick={() => {
                                        window.location.href = PRIVACY_TERM_URL
                                    }}

                                    style={{
                                        height: '20px',
                                        alignSelf: 'stretch',
                                        flexGrow: '0',
                                        fontFamily: "PretendardRegular",
                                        lineHeight: '1.4',
                                        letterSpacing: '-0.28px',
                                        textAlign: 'left',
                                        color: '#D9D9D9',
                                        cursor: 'pointer'
                                    }}
                                >
                                    개인정보처리방침
                                </span>
                            </div>

                        </div>

                    </div>

                    <div
                        style={{
                            flex: '1',
                            display: 'flex',
                            alignItems: 'end',
                            flexDirection: 'column'
                        }}
                    >
                        {/* 인스타그램 아이콘 */}
                        <div
                            onClick={() => {
                                window.location.href = INSTAGRAM_LINK_URL
                            }}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer'
                            }}
                        >
                            <img src={instgramIcon}/>
                        </div>


                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                marginTop: '6px',
                            }}
                        >
                            <span
                                style={{
                                    color: 'white',
                                    fontSize: '14px',
                                    fontWeight: '300',
                                    fontFamily: "PretendardLight",
                                    letterSpacing: '-0.28px',
                                    textAlign: 'end',
                                    width: '100%',
                                    lineHeight: '19.6px',
                                    opacity: '0.8',
                                    lineHeight: '1.4'                               

                                }}
                            >
                                서울특별시 광진구 능동로 120,(화양동, 건국대학교) KU혁신기술관 601호<br/>
                                제주특별자치도 제주시 원남6길 58(도남동), 2층 201호
                            </span>

                        </div>

                        <div style={{flex: '1'}}/>


                        <div style={{display: 'flex'}}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '15px',
                                }}
                            >
                                <span
                                    style={{
                                        fontFamily: "PretendardBold",
                                        color: 'white',
                                        opacity: '0.8',
                                        letterSpacing: '0.1px',
                                    }}
                                >
                                    CEO
                                </span>

                                <span
                                    style={{
                                        marginLeft: '8px',
                                        color: 'white',
                                        fontFamily: "PretendardLight",
                                        opacity: '0.8',
                                        letterSpacing: '0.1px',
                                    }}
                                >
                                    박경진
                                </span>
                            </div>

                            <div
                                style={{
                                    height: '14px',
                                    width: '1px',
                                    background: '#FFFFFF',
                                    opacity: '0.8',
                                    marginLeft: '6px',
                                    marginRight: '6px',
                                }}
                            />

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '15px',
                                }}
                            >
                                <span
                                    style={{
                                        fontFamily: "PretendardBold",
                                        color: 'white',
                                        opacity: '0.8',
                                        letterSpacing: '0.1px',
                                    }}
                                >
                                    E
                                </span>

                                <span
                                    style={{
                                        marginLeft: '8px',
                                        color: 'white',
                                        fontFamily: "PretendardLight",
                                        opacity: '0.8',
                                        letterSpacing: '0.1px',
                                    }}
                                >
                                    contact@anglero.kr
                                </span>
                            </div>

                            <div
                                style={{
                                    height: '14px',
                                    width: '1px',
                                    background: '#FFFFFF',
                                    opacity: '0.8',
                                    marginLeft: '6px',
                                    marginRight: '6px',
                                }}
                            />

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '15px',
                                }}
                            >
                                <span
                                    style={{
                                        fontFamily: "PretendardBold",
                                        color: 'white',
                                        opacity: '0.8',
                                        letterSpacing: '0.1px',
                                    }}
                                >
                                    P
                                </span>

                                <span
                                    style={{
                                        marginLeft: '8px',
                                        color: 'white',
                                        fontFamily: "PretendardLight",
                                        opacity: '0.8',
                                        letterSpacing: '0.1px',
                                    }}
                                >
                                    010-7303-2419
                                </span>
                            </div>
                        
                            <div
                                style={{
                                    height: '14px',
                                    width: '1px',
                                    background: '#FFFFFF',
                                    opacity: '0.8',
                                    marginLeft: '6px',
                                    marginRight: '6px',
                                }}
                            />

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '15px',
                                }}
                            >
                                <span
                                    style={{
                                        fontFamily: "PretendardBold",
                                        color: 'white',
                                        opacity: '0.8',
                                        letterSpacing: '0.1px',
                                        
                                    }}
                                >
                                    사업자 등록번호
                                </span>

                                <span
                                    style={{
                                        marginLeft: '8px',
                                        color: 'white',
                                        fontFamily: "PretendardLight",
                                        opacity: '0.8',
                                        letterSpacing: '0.1px',
                                    }}
                                >
                                    536-10-02317
                                </span>
                            </div>

                        </div>




                    </div>

                </div>

            </div>

        </div>
    )
}

export default Footer;