
import { useEffect, useState } from "react";
import Presenter from "./Presenter";
import { getVideos } from "../../services/videoServices";



const filterObject = {
    "지역": [],
    "최대해상도": [],
    "촬영타입": [],
    "촬영정보": [],
    "인물": [],
    "속도": [],
    "테마": [],
    "색": [],
}





const Container = () => {
    const [isQueryUpdated, setIsQueryUpdated] = useState(true)

    const [filterState, setFilterState] = useState({...filterObject})
    const [keywords, setKeywords] = useState("")

    const [videos, setVideos] = useState([])






    // const sendVideoData = async () => {
    //     console.log("createVideoData");
        
    //     const data = {
    //         'keywords': "",
    //         'filters' : filterState
    //     };
        
    //     // console.log(data)
    //     console.log(JSON.stringify(convertFilters(data)));
        
    //     try {
    //         // axios.post를 사용하여 POST 요청을 보냅니다.
    //         const response = await axios.post('http://localhost:5000/api/v1/video', 
    //             JSON.stringify(convertFilters(data)),  // 데이터를 JSON 문자열로 변환하여 전송
    //             {
    //                 headers: {
    //                     'Content-Type': 'application/json'
    //                 }
    //             }
    //         );
        
    //         console.log('Response:', response.data);
    //     } catch (error) {
    //         console.error('Error:', error.response ? error.response.data : error.message);
    //     }
    // };
    

    const resetFilter = () => {
        console.log("resetFilter")
        console.log(filterObject)
        setFilterState( {
            "최대해상도": [],
            "촬영타입": [],
            "촬영정보": [],
            "인물": [],
            "속도": [],
            "테마": [],
            "색": [],
        })
    }


    useEffect(()=> {
        if (isQueryUpdated) {
            console.log("쿼리가 업데이트 되었습니다");
    
            const fetchHandler = async () => {
                try {
                    const queryObject = {
                        'keywords': keywords,
                        'filters': JSON.parse(JSON.stringify(filterState))
                    };
    
                    const videos = await getVideos(queryObject); // 비동기 함수 대기
                    console.log(videos);
    
                    setVideos(videos); // 데이터를 성공적으로 받아오면 상태 업데이트
                } catch (error) {
                    console.error("비디오 데이터를 가져오는 중 오류 발생:", error);
                } finally {
                    setIsQueryUpdated(false); // 상태 초기화
                }
            };
    
            fetchHandler(); // 비동기 함수 호출
        }
    }, [isQueryUpdated]);


    useEffect(() => {
        console.log("필터 정보가 변경되었습니다")
    }, [filterState]);
    return (
        <Presenter
            filterState={filterState}
            setFilterState={setFilterState}
            resetFilter={resetFilter}

    
            keywords={keywords}
            setKeywords={setKeywords}

            videos={videos}

            setIsQueryUpdated={setIsQueryUpdated}
        />
    )
}

export default Container;