import { BiX } from "react-icons/bi"
import { useEffect, useState } from "react"
import FilterBox from "../base/FilterBox"
import CustomButton from "../base/CustomButton"
import FilterSelector from "./FilterSelector"
import ColorFilterSelector from "./ColorFilterSelector"


const FilterTitle = ({titleText}) => {
    return (
        <span
            style={{
                fontFamily: "PretendardBold",
                fontSize: '18px',
                lineHeight: '1.3',
                letterSpacing: '0.2px',
                color: 'white'
            }}
        >
            {titleText}
        </span>
    )
}


const SubFilterTitle = ({titleText}) => {
    return (
        <span
            style={{
                fontFamily: "PretendardMedium",
                fontSize: '16px',
                lineHeight: '1.3',
                letterSpacing: '0.2px',
                color: 'white'
            }}
        >
            {titleText}
        </span>
    )
}




const filterObject = {
    "최대해상도": [],
    "촬영타입": [],
    "촬영정보": [],
    "인물": [],
    "속도": [],
    "테마": [],
    "색": [],
}

const FilterView = ({closeHandler, originalFilterState, setOriginalFilterState, setIsQueryUpdated}) => {
    const [filterState, setFilterState] = useState(
        JSON.parse(JSON.stringify(originalFilterState))
    )


    const resetFilter = () => {
        setFilterState(filterObject)
    }

    const addFilter = (type, item) => {
        let cObject = {...filterState}

        if(type === "최대해상도") {
            cObject[type] = [item]

            setFilterState(cObject)
        } else if(type === "인물") { 
            if(item === "인물없음") {
                if(!(cObject[type].includes(item))) {
                    cObject[type] = []
                    cObject[type].push(item)
        
                    setFilterState(cObject)
                }
            } else if(item === "인물포함") {
                if(!(cObject[type].includes(item))) {
                    cObject[type] = []
                    cObject[type].push(item)
        
                    setFilterState(cObject)
                }
            } else if(item === "1명" || item === "2명" || item === "3명+") {
                // 현재 배열에서 "1명", "2명", "3명+" 항목이 있는지 확인하고 모두 제거
                cObject[type] = cObject[type].filter(existingItem => 
                    existingItem !== "1명" && existingItem !== "2명" && existingItem !== "3명+"
                );

                // 중복을 방지하고 새로운 항목을 추가
                if (!(cObject[type].includes(item))) {
                    console.log(item, "이 포함되어 있지 않음");
                    console.log(cObject[type]);
                    cObject[type].push(item);

                    setFilterState(cObject); // 상태 업데이트
                }
            } else {
                if(!(cObject[type].includes(item))) {
                    console.log(item, "이 포함되어 있지 않음")
                    console.log(cObject[type])
                    cObject[type].push(item)
        
                    setFilterState(cObject)
                }
            }
        } else if(type === "속도") {
            console.log("3")
            cObject[type] = [item]

            setFilterState(cObject)
        } else if(type === "색") {
            if(item === "흑백") {
                if(!(cObject[type].includes(item))) {
                    cObject[type] = []
                    cObject[type].push(item)
        
                    setFilterState(cObject)
                    
                }
            } else if(item === "컬러") {
                if(!(cObject[type].includes(item))) {
                    cObject[type] = []
                    cObject[type].push(item)
        
                    setFilterState(cObject)
                    
                }
            } else {
                console.log("그외-")
                if(!(cObject[type].includes(item))) {
                    console.log(item, "이 포함되어 있지 않음")
                    console.log(cObject[type])
                    cObject[type].push(item)
        
                    setFilterState(cObject)
                }
            }
        } else {
            console.log("4")
            if(!(cObject[type].includes(item))) {
                cObject[type].push(item)
    
                setFilterState(cObject)
                
            } else {
                console.log("이미 추가되어 있음")
            }
        }

    


    }

    const removeFilter = (type, item) => {
        let cObject = {...filterState}

        let index = cObject[type].indexOf(item); // 배열에서 아이템의 인덱스를 찾음

        if (index !== -1) {
            cObject[type].splice(index, 1); // 인덱스를 기반으로 아이템 제거
        }


        setFilterState(cObject)
    }



    return (
        <div
            style={{
                height: '100%',
            }}
        >
            <div
                style={{
                    zIndex: '999',
                    padding: '24px',
                    height: 'calc(100% - 48px)',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {/* 타이틀 항목 */}
                <>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                flex: '1',
                                fontFamily: "PretendardBold",
                                fontSize: '18px',
                                lineHeight: '1.3',
                                letterSpacing: '0.2px',
                                color: 'white'
                            }}
                        >
                            상세 필터
                        </span>

                        <BiX 
                            color="white" 
                            size={24} 
                            strokeWidth={1}
                            onClick={()=>{
                                closeHandler()
                            }}
                        />

                    </div>

                    <div style={{width: '100%', height: '1px', background: 'white', marginTop: '24px'}}/>
                </>

                <div
                    style={{
                        flex :'1',
                        overflow: 'scroll'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '18px'
                        }}
                    >
                        <FilterTitle titleText={"최대해상도"}/>
                        <FilterSelector 
                            filterList={["FHD", "3K", "4K", "5K+"]}
                            selectedList={filterState["최대해상도"]}
                            filterType={'최대해상도'}
                            addFilter={addFilter}
                            removeFilter={removeFilter}
                        />


                    </div>

                    <div style={{height: '36px'}}/>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <FilterTitle titleText={"촬영타입"}/>
                        <div style={{height: '18px'}}/>

                        <>
                            <SubFilterTitle titleText={"샷 크기"}/>
                            <FilterSelector 
                                filterList={["접사", "클로즈업", "미디엄샷", "풀샷", "익스트림 와이드"]}
                                selectedList={filterState["촬영타입"]}
                                filterType={'촬영타입'}
                                addFilter={addFilter}
                                removeFilter={removeFilter}
                            />

                        </>
                        <div style={{height: '12px'}}/>
                        <>
                            <SubFilterTitle titleText={"무브"}/>
                            <FilterSelector 
                                filterList={["픽스(삼각대)", "부드러운 움직임", "핸드헬드", "FPV"]}
                                selectedList={filterState["촬영타입"]}
                                filterType={'촬영타입'}
                                addFilter={addFilter}
                                removeFilter={removeFilter}
                            />
                        </>
                    </div>

                    <div style={{height: '36px'}}/>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <FilterTitle titleText={"촬영정보"}/>
                        <div style={{height: '18px'}}/>

                        <>
                            <SubFilterTitle titleText={"분류"}/>

                            <FilterSelector 
                                filterList={["일반", "드론", "타임랩스", "수중촬영", "VFX"]}
                                selectedList={filterState["촬영정보"]}
                                filterType={'촬영정보'}
                                addFilter={addFilter}
                                removeFilter={removeFilter}
                            />
                        </>
                        <div style={{height: '12px'}}/>
                        <>
                            <SubFilterTitle titleText={"시간"}/>

                            <FilterSelector 
                                filterList={["낮", "밤", "일출&일몰", "시간 미특정"]}
                                selectedList={filterState["촬영정보"]}
                                filterType={'촬영정보'}
                                addFilter={addFilter}
                                removeFilter={removeFilter}
                            />
                        </>

                        <div style={{height: '12px'}}/>
                        <>
                            <SubFilterTitle titleText={"장소"}/>

                            <FilterSelector 
                                filterList={["실내", "실외", "호리존", "장소 미특정"]}
                                selectedList={filterState["촬영정보"]}
                                filterType={'촬영정보'}
                                addFilter={addFilter}
                                removeFilter={removeFilter}
                            />
                        </>
                    </div>

                    <div style={{height: '36px'}}/>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <FilterTitle titleText={"인물"}/>

                        <div style={{height: '18px'}}/>

                        <>
                            <SubFilterTitle titleText={"인물 없음/포함"}/>

                            <FilterSelector 
                                filterList={["인물없음", "인물포함"]}
                                selectedList={filterState["인물"]}
                                filterType={'인물'}
                                addFilter={addFilter}
                                removeFilter={removeFilter}
                            />
                        </>
                        

                        {
                            !(filterState['인물'].includes('인물포함')) ? 
                                null
                            :
                                <>

                                    <div style={{height: '12px'}}/>

                                    <>
                                        <SubFilterTitle titleText={"인원수"}/>

                                        <FilterSelector 
                                            filterList={["1명", "2명", "3명+"]}
                                            selectedList={filterState["인물"]}
                                            filterType={'인물'}
                                            addFilter={addFilter}
                                            removeFilter={removeFilter}
                                        />
                                    </>

                                    <div style={{height: '12px'}}/>
                                    
                                    <>
                                        <SubFilterTitle titleText={"연령"}/>

                                        <FilterSelector 
                                            filterList={["아기", "어린이", "청소년", "성인", "중장년"]}
                                            selectedList={filterState["인물"]}
                                            filterType={'인물'}
                                            addFilter={addFilter}
                                            removeFilter={removeFilter}
                                        />
                                    </>

                                    <div style={{height: '12px'}}/>

                                    <>
                                        <SubFilterTitle titleText={"성별"}/>

                                        <FilterSelector 
                                            filterList={["남성", "여성", "커플"]}
                                            selectedList={filterState["인물"]}
                                            filterType={'인물'}
                                            addFilter={addFilter}
                                            removeFilter={removeFilter}
                                        />
                                    </>
                                </>
                        }
                    </div>

                    <div style={{height: '36px'}}/>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <FilterTitle titleText={"속도"}/>
                        <FilterSelector 
                            filterList={["저배속 | Slow", "보통 | Realtime", "고배속 | Fast"]}
                            selectedList={filterState["속도"]}
                            filterType={'속도'}
                            addFilter={addFilter}
                            removeFilter={removeFilter}
                        />
                    </div>

                    <div style={{height: '36px'}}/>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <FilterTitle titleText={"테마"}/>

                        <div style={{height: '18px'}}/>

                        <>
                            <SubFilterTitle titleText={"분야"}/>

                            <FilterSelector 
                                filterList={["여행", "음악", "스포츠", "요리", "교육", "뷰티", "라이프스타일", "자연", "과학", "미술", "애니메이션"]}
                                selectedList={filterState['테마']}
                                filterType={'테마'}
                                addFilter={addFilter}
                                removeFilter={removeFilter}  
                            />
                        </>

                        <div style={{height: '12px'}}/>

                        <>
                            <SubFilterTitle titleText={"분위기"}/>

                            <FilterSelector 
                                filterList={["밝음", "어두움", "차가움", "고전적", "현대적", "신비로움", "감성적", "따뜻함", "귀여움", "영화적"]}
                                selectedList={filterState['테마']}
                                filterType={'테마'}
                                addFilter={addFilter}
                                removeFilter={removeFilter}   
                            />
                        </>
                    </div>

                    <div style={{height: '36px'}}/>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',

                        }}
                    >
                        <FilterTitle titleText={"색감"}/>

                        <div style={{height: '18px'}}/>

                        <>
                            <SubFilterTitle titleText={"흑백여부"}/>

                            <FilterSelector 
                                filterList={["흑백", "컬러"]}
                                selectedList={filterState['색']}
                                filterType={'색'}
                                addFilter={addFilter}
                                removeFilter={removeFilter}   
                                
                            />
                        </>


                        {
                            !filterState['색'].includes('컬러') ? 
                                null
                            :
                                <>

                                    <div style={{height: '12px'}}/>

                                    <>
                                        <SubFilterTitle titleText={"색상"}/>

                                        <ColorFilterSelector 
                                            filterList={["검정색", "회색", "흰색", "빨강색", "주황색", "노랑색", "연두색", "초록색", "하늘색", "파랑색", "보라색", "분홍색"]}
                                            selectedList={filterState['색']}
                                            filterType={'색'}
                                            addFilter={addFilter}
                                            removeFilter={removeFilter}  
                                        />
                                    </>
                                </>
                        }
                    </div>

                    <div style={{height: '24px'}}/>
                </div>


                {/* 버튼 항목 */}
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        
                        borderTop: '1px solid white',
                        paddingTop: '18px'
                    }}
                >
                    <CustomButton 
                        buttonText={"닫기"}
                        eventHandler={()=> {
                            closeHandler()
                        }}
                    />

                    <div
                        style={{
                            flex: '1',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'end',
                            columnGap: '24px'
                        }}
                    >
                        <CustomButton 
                            buttonText={"필터 초기화"}
                            eventHandler={resetFilter}
                        />
                        <CustomButton 
                            buttonText={"필터 적용"}
                            eventHandler={()=> {
                                setOriginalFilterState(filterState)

                                setIsQueryUpdated(true)
                                closeHandler()
                            }}
                        />

                    </div>
                </div>

            </div>
        
        
        </div>
    )
}

export default FilterView