import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { HomePage } from './pages/Home';
import { VideoDetailPage } from './pages/VideoDetail';
import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/video/:id" element={<VideoDetailPage />} />
      </Routes>
    </Router>
  );
}

export default App;
