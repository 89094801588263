
import { useEffect, useState } from "react";
import Presenter from "./Presenter";
import { useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import { getVideoDetail } from "../../services/videoServices";

const Container = () => {
    const navigate = useNavigate()

    const { id } = useParams(); // URL에서 id 값을 추출
    const [video, setVideo] = useState(null); // 비디오 데이터를 저장할 상태
    const [partnerVideos, setPartnerVideos] = useState([])
    const [similarVideos, setSimilarVideos] = useState([])

    const [loading, setLoading] = useState(true); // 로딩 상태
    const [error, setError] = useState(null); // 에러 상태

    useEffect(() => {

        const fetchVideo = async () => {
            console.log("fetchVideo");
            try {
                setLoading(true); // 로딩 상태 설정
        
                // 서비스 함수 호출
                const { video, partnerVideos, similarVideos } = await getVideoDetail(id);
        
                // 상태 업데이트
                setVideo(video);
                setPartnerVideos(partnerVideos);
                setSimilarVideos(similarVideos);
        
                console.log(partnerVideos);
        
                setLoading(false); // 로딩 완료
            } catch (error) {
                navigate('/'); // 에러 발생 시 메인 페이지로 이동
                setError(error); // 에러 상태 업데이트
                setLoading(false);
            }
        };
    
        fetchVideo();
    }, [id, navigate]); // id가 변경될 때마다 API 호출

    

    if (loading) return <div>Loading...</div>; // 로딩 중일 때 표시할 내용
    if (error) return <div>Error: {error.message}</div>; // 에러 발생 시 표시할 내용
    if (!video) return <div>No video found</div>; // 비디오가 없을 때 표시할 내용


    return (
        <Presenter 
            video={video}
            partnerVideos={partnerVideos}
            similarVideos={similarVideos}
        />
    )
}

export default Container;