import { useNavigate } from 'react-router-dom'
import mainLogo from '../../assets/images/logo.svg'

const Header = () => {
    const navigate = useNavigate()

    
    return (
        <div
            style={{
                height: '100px',
                display: 'flex',
                justifyContent: 'center',
                position: 'fixed',
                width: '100%',
                background: 'black',
                zIndex: '1000'
            }}
        >
            <div
                style={{
                    width: '1200px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <div
                    onClick={()=> {navigate('/')}}
                    style={{
                        width: '200px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <img src={mainLogo} style={{width: '100%', }}/>

                </div>

                <ul
                    style={{
                        display: 'flex',
                        marginLeft: 'auto',
                        color: 'white',
                        columnGap: '28px',
                        fontSize: '18px',
                        fontFamily: "PretendardSemiBold",
                        padding: '48px',
                        letterSpacing: '1px'
                    }}
                >
                    
                    <li
                        onClick={() => window.open('https://anglero.shop')}
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        About
                    </li>

                    <li
                        onClick={()=> {
                            navigate('/')
                        }}
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        Footage
                    </li> 

                    <li
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        Partner
                    </li> 
                </ul>


                <div
                    onClick={() => window.open('https://forms.gle/vTyZVssswqn8kcY49', '_blank')}
                    style={{
                        background: '#D6061C',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '10px 18px',
                        borderRadius: '8px',
                        cursor: 'pointer',
    
                    }}
                >
                    <span
                        style={{
                            color: 'white',
                            fontFamily: "PretendardSemiBold",
                            fontSize: '16px',
                            alignItems: 'center',
                            letterSpacing: '1px'
                        }}
                    >
                        문의하기
                    </span>

                </div>

            </div>




        </div>
    )
}

export default Header