import iconCancel from '../../assets/icons/icon-cancel.svg'

const FilterTag = ({item, deleteHandler}) => {
    return (
        <div

            style={{
                padding: '16px 20px',
                border: '1px solid #ffffff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '10px'
            }}
        >
            <span style={{
                color: 'white',
                fontFamily: "PretendardRegular",
                fontSize: '18px',
                verticalAlign: 'center',
            }}>
                {item}
            </span>

            <div
                onClick={()=> {
                    deleteHandler()
                }}

                style={{
                    marginLeft: '16px',
                    width: '12px',
                    height: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                }}
            >
                <img src={iconCancel} style={{width: '100%'}}/>
            </div>
        </div>
    )
}

export default FilterTag;