
import Footer from '../../components/base/Footer'
import Header from '../../components/base/Header'

import iconDownload from "../../assets/icons/icon-download.svg"
import iconShare from "../../assets/icons/icon-share.svg"

import VideoTag from '../../components/base/VideoTag'
import { extractFileName, splitByToken } from '../../utils/StringUtils'
import VideoItem from '../../components/main/VideoItem'
import { useRef, useState } from 'react'
import { downloadVideo } from '../../services/videoServices'

import { BiSolidDownvote } from "react-icons/bi";
import DotsSpinner from '../../components/base/DotsSpinner'


const Presenter = ({video, partnerVideos, similarVideos}) => {
    const videoRef = useRef(null)
    const [downloading, setDownloading] = useState(false); // 메시지 상태 관리

    const handleDownload = async (videoId, fileName) => {
        try {
            // 다운로드 시작 메시지
            setDownloading(true)
            
            // 비디오 다운로드 함수 호출 (downloadVideo)
            await downloadVideo(videoId, fileName);

            // 다운로드 완료 후 메시지 지우기
            setTimeout(() => {
                setDownloading(false)
            }, 1400); // 1.4초 후 메시지 제거
        } catch (error) {
            console.error('Error downloading video:', error);

            setDownloading(false)
        }
    };

    


    return (
        <>


            <Header/>

            {/* 검색 섹션 */}
            <div
                style={{
                    background: 'black',
                    width: '100%',
                    paddingTop: '200px',
                    paddingBottom: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        width: 'calc(100% - 500px)',
                        minWidth: '1420px',
                        maxWidth: 'calc(100% - 500px)',
                        flexGrow: '0',
                        height: '578px',
                        // border: '1px solid white',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <div
                        style={{
                            width: '905px',
                            height: '527px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <video 
                            ref={videoRef}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                            }} 
                            controls
                            controlsList="nodownload"  // 다운로드 버튼 비활성화
                        >
                            <source src={video?.video_path} type="video/mp4"/>
                        </video>


                    </div>

                    <div
                        style={{
                            marginLeft: 'auto',
                            width: '444px',
                            height: '578px',
                            display: 'flex',

                        }}
                    >
                        <div
                            style={{
                                flex: '1',
                                display: 'flex',
                                paddingTop: '20px',
                                paddingBottom: '20px',
                                
                            }}
                        >
                            <div
                                style={{
                                    // border: '1px solid white',
                                    flex: '1',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <span
                                    style={{
                                        color: '#9d9d9d',
                                        opacity: '0.5',
                                        fontFamily: "PretendardRegular",
                                        fontSize: '14px',
                                        lineHeight: '1.5',
                                        textAlign: 'left',
                                        letterSpacing: 'normal',
                                        objectFit: 'contain'
                                    }}
                                >
                                    {video?.label}
                                </span>

                                <div
                                    style={{
                                        marginTop: '28px',
                                        marginBottom: '28px',
                                    }}
                                >
                                    <span
                                        style={{
                                            fontFamily: "PretendardBold",
                                            color: 'white',
                                            lineHeight: '1.5',
                                            fontSize: '24px',
                                            alignSelf: 'stretch',
                                            height: '36px',
                                            objectFit: 'contain'
                                        }}
                                    >
                                        {video?.title}
                                    </span>

                                </div>

                                <span
                                    style={{
                                        color: '#fff',
                                        opacity: '0.5',
                                        fontFamily: "PretendardRegular",
                                        fontSize: '14px',
                                        lineHeight: '1.5',
                                        textAlign: 'left',
                                        letterSpacing: 'normal',
                                        objectFit: 'contain',
                                        margin: '0 0 20px'
                                    }}
                                >
                                    by {video?.partner_name}
                                </span>
                                
                                <div
                                    style={{
                                        // border: '1px solid white',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <span
                                        style={{
                                            color: 'white',
                                            fontSize: '16px',
                                            fontFamily: "PretendardRegular",
                                            lineHeight: '1.5',
                                            textAlign: 'left'
                                        }}
                                    >
                                        {video?.main_region}
                                    </span>

                                    <div style={{
                                        width: '1.5px',
                                        height: '12px',
                                        background: '#ffffff60',
                                        marginLeft: '12px',
                                        marginRight: '12px'
                                    }}/>
                                    
                                    <>
                                        <div
                                            style={{
                                                width: '16px',
                                                height: '16px',
                                                background: '#d9d9d9',
                                                borderRadius: '24px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <BiSolidDownvote size={11}/>

                                        </div>

                                        <div style={{width: '6px'}}/>

                                        <span
                                            style={{
                                                color: 'white',
                                                fontSize: '16px',
                                                fontFamily: "PretendardRegular",
                                                lineHeight: '1.5',
                                                textAlign: 'left'
                                            }}
                                        >
                                            {video?.download_count}
                                        </span>              
                                    
                                    </>

                                </div>


                                <div
                                    style={{
                                        paddingTop: '28px',
                                        paddingBottom: '28px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        rowGap: '10px'
                                    }}
                                >

                                    <div
                                        style={{
                                            display: 'flex'
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: 'white',
                                                flex: '2',
                                                fontFamily: "PretendardBold",
                                                fontSize: '14px',
                                                lineHeight: '1.5',
                                            }}
                                        >
                                            해상도
                                        </span>
                                        <span
                                            style={{
                                                color: 'white',
                                                flex: '3',
                                                fontFamily: "PretendardRegular",
                                                fontSize: '14px',
                                                lineHeight: '1.5',
                                            }}
                                        >
                                            {video?.resolution}
                                        </span>
                                    </div>

                                    <div
                                        style={{
                                            display: 'flex'
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: 'white',
                                                flex: '2',
                                                fontFamily: "PretendardBold",
                                                fontSize: '14px',
                                                lineHeight: '1.5',
                                            }}
                                        >
                                            카메라
                                        </span>
                                        <span
                                            style={{
                                                color: 'white',
                                                flex: '3',
                                                fontFamily: "PretendardRegular",
                                                fontSize: '14px',
                                                lineHeight: '1.5',
                                            }}
                                        >
                                            {video?.camera_model}
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex'
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: 'white',
                                                flex: '2',
                                                fontFamily: "PretendardBold",
                                                fontSize: '14px',
                                                lineHeight: '1.5',
                                            }}
                                        >
                                            FPS
                                        </span>
                                        <span
                                            style={{
                                                color: 'white',
                                                flex: '3',
                                                fontFamily: "PretendardRegular",
                                                fontSize: '14px',
                                                lineHeight: '1.5',
                                            }}
                                        >
                                            {video?.fps}
                                        </span>
                                    </div>
                                </div>

                                
                                {/* 다운로드 및 공유하기 */}
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div
                                        onClick={() => {
                                            if(!downloading) {
                                                handleDownload(video?.id, extractFileName(video?.video_path))
                                            }
                                        }}
                                        style={{
                                            display: 'flex',
                                            color: 'white',
                                            border: '1px solid white',
                                            padding: '8px 24px',
                                            borderRadius: '10px',
                                            alignItems: 'center',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: '14px',
                                                fontFamily: "PretendardMedium",
                                                letterSpacing: '0.28px',
                                            }}
                                        
                                        >
                                            Download Sample
                                        </span>


                                        {/* 다운로드 아이콘 */}
                                        <div
                                            style={{
                                                marginLeft: '10px',
                                                width: '24px',
                                                height: '24px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                
                                            }}
                                        >
                                            {
                                                downloading?
                                                    <DotsSpinner size={24} dotSize={4} dotCount={8} speed={0.8} color="#EFEFEF" />
                                                :

                                                    <img src ={iconDownload} style={{
                                                        width: '100%',
                                                        height: '100%'
                                                    }}/>

                                            }

                                            {/* {
                                                message && <DotsSpinner/>
                                                
                                            } */}


                                        </div>



                                    </div>

                                    <div
                                        style={{
                                            marginLeft: '10px',
                                            width: '32px',
                                            height: '32px',
                                            background: '#4a4a4a',
                                            borderRadius: '24px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer'
                                        }}
                                    >
                                            <img src ={iconShare} style={{
                                                width: '16px',
                                                height: '16px',
                                                objectFit: 'cover',
                                                marginRight: '1px'
                                            }}/>

                                    </div>

                                </div>


                                <div
                                    style={{
                                        paddingTop: '28px',
                                        paddingBottom: '28px'
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            rowGap: '10px',
                                            columnGap: '10px',
                                        }}
                                    >
                                        {
                                            
                                            [...splitByToken(video?.main_tags, '|')].map((item, index) => {
                                                return (
                                                    <VideoTag item = {item} key={index}/>
                                                )
                                            })
                                        }

                                    </div>

                                </div>




                                <span
                                    style={{
                                        color: '#fff',
                                        opacity: '0.5',
                                        fontFamily: "PretendardRegular",
                                        fontSize: '14px',
                                        lineHeight: '1.5',
                                        textAlign: 'left',
                                        letterSpacing: 'normal',
                                        objectFit: 'contain'
                                    }}
                                >
                                    
                                    해당 영상 클립은 앵글로에서 보유 및 유통 중인 영상 소스입니다.<br/>
                                    비상업적 사용을 포함한 모든 무단 사용을 금하며<br/>
                                    원본 영상이 필요하신 경우 www.anglero.kr을 통해 문의 부탁드립니다.
                                </span>

                            </div>

                        </div>

                    </div>

                </div>



            </div>


            {/* 파트너의 다른 영상 */}
            <div
                style={{
                    width: '100%',
                    paddingLeft: '50px',
                    paddingRight: '50px',
                    background: 'black',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        width: 'calc(100% - 100px)',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <span
                        style={{
                            color: 'white',
                            fontFamily: "PretendardBold",
                            fontSize: '24px',
                            lineHeight: '36px',
                        }}
                    >
                        파트너의 다른 영상
                    </span>

                </div>

                <div
                    style={{
                        display: 'grid',
                        marginTop: '39px',
                        width: 'calc(100% - 100px)',
                        gridTemplateColumns: 'repeat(4, 1fr)',
                        alignItems: 'center',
                        justifyContent: 'center',
                        rowGap: '20px',
                        columnGap: '20px',
                    }}
                >
                    {
                        [...partnerVideos].map((item, index) => {
                            return (
                                <VideoItem
                                    key={index}
                                    item={item}
                                />
                            )
                        })
                    }


                </div>

            </div>

            <div style={{
                height: '100px',
            }}/>


            {/* 유사한 클립 */}
            <div
                style={{
                    width: '100%',
                    paddingLeft: '50px',
                    paddingRight: '50px',
                    background: 'black',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        width: 'calc(100% - 100px)',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <span
                        style={{
                            color: 'white',
                            fontFamily: "PretendardBold",
                            fontSize: '24px',
                            lineHeight: '36px',
                        }}
                    >
                        유사한 클립
                    </span>

                </div>

                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4, 1fr)',
                        marginTop: '39px',
                        width: 'calc(100% - 100px)',
                        alignItems: 'center',
                        justifyContent: 'center',
                        rowGap: '20px',
                        columnGap: '20px',
                    }}
                >
                    {
                        [...similarVideos].map((item, index) => {
                            return (
                                <VideoItem
                                    key={index}
                                    item={item}
                                />
                            )
                        })
                    }


                </div>

            </div>


            {/* Footer */}
            <Footer/>

        </>
    )
}

export default Presenter