import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reset from 'styled-reset'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  ${reset}
  /* other styles */
`

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <>
    <GlobalStyle/>
    <App />
  </>


);

