import axios from 'axios';
import qs from 'qs';
import { FILTER_INFO } from "../constants/filters";


function convertFilters(data) {
    const result = { ...data };

    // data의 filters 객체를 순회하면서 변환
    Object.keys(result.filters).forEach(filterKey => {
        if (FILTER_INFO[filterKey]) {
            result.filters[filterKey] = result.filters[filterKey].map(item => FILTER_INFO[filterKey][item] || item);
        }
    });

    return result;
}


export const getVideos = async (queryData) => {

    // console.log(data)
    console.log(JSON.stringify(convertFilters(queryData)))

    const queryString = qs.stringify(queryData, { arrayFormat: 'brackets' });

    try {
        const response = await axios.get(`${process.env.REACT_APP_PRD_API_URL}/video?${queryString}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        console.log('Response:', response.data);

        return response?.data?.video;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        throw error; // 에러 발생 시 호출한 곳에서 처리하도록 던짐
    }
};




export const getVideoDetail = async (id) => {
    try {
        // API 호출
        const response = await axios.get(`${process.env.REACT_APP_PRD_API_URL}/video/${id}`);

        // 응답 데이터 반환
        return {
            video: response.data.video,
            partnerVideos: response.data.partnerVideos,
            similarVideos: response?.data?.similarVideos
        };
    } catch (error) {
        console.error('Error fetching video details:', error);
        throw error; // 에러 발생 시 호출한 쪽에서 처리할 수 있도록 에러를 던짐
    }
};



export const downloadVideo = async (videoId, fileName) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_PRD_API_URL}/video/${videoId}/download`, {
            headers: {
                'Content-Type': 'application/json'
            },
            responseType: 'blob' // 파일 데이터를 Blob 형식으로 받아옴
        });

        // 서버가 보낸 Content-Disposition 헤더에서 파일명 추출
        const disposition = response.headers['content-disposition'];


        console.log(disposition)

        if (disposition && disposition.includes('filename=')) {
            const fileNameMatch = disposition.match(/filename="?(.+)"?/);
            if (fileNameMatch.length > 1) {
                fileName = fileNameMatch[1]; // 서버에서 전달된 파일명으로 설정
            }
        }

        // 파일 데이터를 Blob으로 변환하여 다운로드 처리
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName; // 서버에서 지정한 파일 이름으로 다운로드
        document.body.appendChild(a);
        a.click();
        a.remove(); // 다운로드 후 태그 제거

        console.log('File downloaded successfully');
    } catch (error) {
        console.error('Error downloading the file:', error.response ? error.response.data : error.message);
        throw error; // 에러 발생 시 호출한 곳에서 처리하도록 던짐
    }
};
