import { useState } from "react";
import { BiSearch } from "react-icons/bi";

const SearchButton = ({eventHandler}) => {
    const [mouseOver, setMouseOver] = useState(false)


    const handleMouseOver = () => {
        setMouseOver(true)
    };

    const handleMouseOut = () => {
        setMouseOver(false)
    };
  

    return (
        <div
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={()=> {eventHandler()}}

            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}

        >

            <BiSearch 
                style={{
                    width: '26px',
                    height: '26px',
                    objectFit: 'contain',
                    flexGrow: '0',
                    color: mouseOver ? 'white' : '#56585E',
                    transition: 'color 0.2s ease' // 색상이 변경될 때 0.3초 동안 부드럽게 변화
                }}
            />
        </div>
    )
}

export default SearchButton;