const RegionFilterItem = ({item, eventHandler, activateState}) => {
    return (
        <div
            onClick={()=>{eventHandler()}}
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '9px',
                marginBottom: '9px',
                cursor: 'pointer',
            }}
        >
            <span
                style={{
                    height: '43px',
                    alignSelf: 'stretch',
                    flexGrow: '0',
                    
                    // outfit 폰트
                    fontFamily: "PretendardMedium",
                    
                    fontSize: '24px',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: '1.8',
                    letterSpacing: '-0.48px',
                    textAlign: 'left',
                    color: activateState? 'white' : '#56585e',
                    transition: 'color 0.2s ease'
                }}
            >
                {item}
            </span>

            <div style={{
                width: '100%',
                height: '1px',
                background: activateState? 'white' : '#56585e',
                transition: 'color 0.2s ease',
                flexGrow: '0'
            }}/>

        </div>
    )
}

export default RegionFilterItem