import iconCancel from '../../assets/icons/icon-cancel.svg'
import { COLOR_MAPPING } from '../../constants/colors';

const ColorFilterTag = ({item, deleteHandler}) => {
    return (
        <div

            style={{
                padding: '16px 20px',
                border: '1px solid #ffffff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '10px'
            }}
        >
            <span style={{
                color: 'white',
                fontFamily: "PretendardRegular",
                fontSize: '18px',
                verticalAlign: 'center',
            }}>
                {item}
            </span>

            {
                item === "컬러" ?
                    null

                :
                <div
                    style={{
                        width: '14px',
                        height: '14px',
                        marginLeft: '8px',
                        background: COLOR_MAPPING[item],
                        borderRadius: '8px',
                        border: '1px solid #FFFFFF33'
                    }}
                >
                </div>

            }


            <div
                onClick={()=> {
                    deleteHandler()
                }}

                style={{
                    marginLeft: item === "컬러"? '16px' :'8px',
                    width: '12px',
                    height: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                }}
            >
                <img src={iconCancel} style={{width: '100%'}}/>
            </div>
        </div>
    )
}

export default ColorFilterTag;