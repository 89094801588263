export const COLOR_MAPPING = {
    '검정색': '#000000',
    '회색': '#848484',
    '흰색': '#FFFFFF',
    '빨강색': '#E72525',
    '주황색': '#F48700',
    '노랑색': '#F1F129',
    '연두색': '#A9E418',
    '초록색': '#06D506',
    '하늘색': '#0BBBF5',
    '파랑색': '#1F55F8',
    '보라색': '#7F00FF',
    '분홍색': '#FFC0CB'
};
