import iconChevronDown from '../../assets/icons/icon-chevron-down.svg'

const FilterItem = ({filterText, selected, addHandler, removeHandler, selectedList}) => {
    return (
        <div
            onClick={()=>{
                if(selectedList.includes(filterText)) {
                    removeHandler(filterText)
                } else {
                    addHandler(filterText)
                }
            }}
            style={{
                background: '#1A1A1A',
                flexGrow: '0',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 16px',
                borderRadius: '10px',
                border: selected ? '1px solid white' : '1px solid #1A1A1A',
                cursor: 'pointer'
            }}
        >
            <span
                style={{
                    fontFamily: "PretendardRegular",
                    color: '#FFF',
                    opacity: '0.8',
                    fontSize: '15px',
                    lineHeight: '1.3',
                    letterSpacing: '0.2px',
                    textAlign: 'left',
                    flexGrow: '0',
                }}
            >
                {filterText}
            </span>

        </div>
    )
}

export default FilterItem;