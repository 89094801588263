const splitByToken = (string, token) => {
    if (!string) return []
    return string.split(token)
}

const extractFileName = (pathURL) => {
    return pathURL.substring(pathURL.lastIndexOf('/') + 1);
}

module.exports = {
    splitByToken,
    extractFileName
}