const VideoTag = ({item}) => {
    return (
        <div
            style={{
                border: '1px solid white',
                padding: '4px 20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '18px'
            }}
        >
            <span
                style={{
                    color: 'white',
                    fontSize: '16px',
                    fontFamily: "PretendardRegular",
                    lineHeight: '1.3',
                    textShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
                    verticalAlign: 'center'
                }}
            >
                {item}
            </span>
        </div>
    )
}

export default VideoTag;